var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-toolbar-title',[_vm._v(" Unprocessed Files ")]),_c('v-spacer'),_c('v-text-field',{staticStyle:{"max-width":"300px"},attrs:{"hide-details":"","prepend-inner-icon":"search","placeholder":"Search","outlined":"","dense":"","rounded":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.isDialog)?_c('v-btn',{attrs:{"icon":"","color":"redPop"},on:{"click":_vm.closeDialog}},[_c('v-icon',[_vm._v("close")])],1):_vm._e()],1),_c('v-card-text',{staticClass:"my-0 py-0"},[_c('ag-grid-vue',{style:({width: '100%', height: _vm.height}),attrs:{"groupDisplayType":"multipleColumns","animateRows":false,"rowGroupPanelShow":'always',"autoSizeStrategy":{
          type: 'fitGridWidth',
          defaultMinWidth: 125,
          columnLimits: [
            {
              colId: 'documentationStatus',
              minWidth: 120,
              width: 120,
            },
            {
              colId: 'fileNumber',
              minWidth: 180,
              width: 180,
            },
            {
              colId: 'vesselVoyage',
              minWidth: 200,
            },
            {
              colId: 'shipperName',
              minWidth: 200,
            },
            {
              colId: 'onBehalfShipperName',
              minWidth: 200,
            },
            {
              colId: 'consigneeName',
              minWidth: 200,
            },
          ],
        },"enableRangeSelection":true,"suppressAggFuncInHeader":true,"columnDefs":_vm.columns,"rowData":_vm.files,"sideBar":_vm.sidebar,"statusBar":_vm.statusBar,"defaultColDef":_vm.defaultColDef,"getContextMenuItems":_vm.getContextMenuItems},on:{"grid-ready":_vm.onGridReady,"rowClicked":_vm.viewShipmentFile}})],1)],1),_c('v-dialog',{attrs:{"width":"90vw","fullscreen":_vm.$vuetify.breakpoint.mobile},model:{value:(_vm.previewShipmentFile),callback:function ($$v) {_vm.previewShipmentFile=$$v},expression:"previewShipmentFile"}},[(_vm.previewShipmentFile && _vm.selectedFile && _vm.selectedFile.id)?_c('ViewFile',{key:_vm.previewKey,attrs:{"fileId":_vm.selectedFile.id,"allowShipmentProcess":true},on:{"Cancelled":_vm.cancelFile,"close":function($event){_vm.previewShipmentFile = false},"ProcessedFile":_vm.updateData,"refresh":_vm.getFiles}}):_vm._e()],1),(_vm.$vuetify.breakpoint.mobile)?_c('div',{staticClass:"bottomBar"},[_c('v-text-field',{attrs:{"hide-details":"","prepend-inner-icon":"search","placeholder":"Search","outlined":"","dense":"","rounded":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }