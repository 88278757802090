<template>
  <div>
    <v-card flat>
        <v-toolbar flat dense>
          <v-toolbar-title> Unprocessed Files </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
            hide-details
            prepend-inner-icon="search"
            placeholder="Search"
            v-model="search"
            outlined
            dense
            rounded
            clearable
            style="max-width: 300px"
          ></v-text-field>
          <v-btn @click="closeDialog" icon color="redPop" v-if="isDialog"
          ><v-icon>close</v-icon>
        </v-btn>
        </v-toolbar>
     
      <v-card-text class="my-0 py-0">
        <ag-grid-vue
          groupDisplayType="multipleColumns"
          :animateRows="false"
          :rowGroupPanelShow="'always'"
          :autoSizeStrategy="{
            type: 'fitGridWidth',
            defaultMinWidth: 125,
            columnLimits: [
              {
                colId: 'documentationStatus',
                minWidth: 120,
                width: 120,
              },
              {
                colId: 'fileNumber',
                minWidth: 180,
                width: 180,
              },
              {
                colId: 'vesselVoyage',
                minWidth: 200,
              },
              {
                colId: 'shipperName',
                minWidth: 200,
              },
              {
                colId: 'onBehalfShipperName',
                minWidth: 200,
              },
              {
                colId: 'consigneeName',
                minWidth: 200,
              },
            ],
          }"
          :style="{width: '100%', height: height}"
          :enableRangeSelection="true"
          :suppressAggFuncInHeader="true"
          :columnDefs="columns"
          :rowData="files"
          :sideBar="sidebar"
          :statusBar="statusBar"
          :defaultColDef="defaultColDef"
          :getContextMenuItems="getContextMenuItems"
          @grid-ready="onGridReady"
          @rowClicked="viewShipmentFile"
        ></ag-grid-vue>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="previewShipmentFile"
      width="90vw"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <ViewFile
        :key="previewKey"
        v-if="previewShipmentFile && selectedFile && selectedFile.id"
        @Cancelled="cancelFile"
        :fileId="selectedFile.id"
        @close="previewShipmentFile = false"
        :allowShipmentProcess="true"
        @ProcessedFile="updateData"
        @refresh="getFiles"
      />
    </v-dialog>
    <div class="bottomBar" v-if="$vuetify.breakpoint.mobile">
      <v-text-field
        hide-details
        prepend-inner-icon="search"
        placeholder="Search"
        v-model="search"
        outlined
        dense
        rounded
        clearable
      ></v-text-field>
    </div>
  </div>
</template>
<script>
/* eslint-disable vue/no-unused-components */

import { AgGridVue } from "ag-grid-vue";
import OrganisationName from "../GridComponents/Organisation.vue";
import PortCell from "../GridComponents/Port.vue";
import UserCell from "../GridComponents/User.vue";
import ViewFile from "../SystemComponents/PreviewFileModal.vue";
export default {
  components: {
    AgGridVue,
    OrganisationName,
    PortCell,
    UserCell,
    ViewFile,
  },
  props: {opened:{
    type: Boolean,
    default: false,
  }, isDialog: {
    type: Boolean,
    default: false,
  }, selectedUser: Object, filter: Object, filterKey: Number, week: String, height: {
    type: String,
    default: "80vh",
  }},
  data: () => ({
    defaultColDef: {
      sortable: true,
      resizable: true,
      filter: true,
      enableRowGroup: true,
      enableValue: true,
      enablePivot: true,
      flex: 1,
      minWidth: 100,
      cellClass: "d-flex align-center",
      cellStyle: { textAlign: "center", fontSize: "11px" },
    },
    files: null,
    filters: {},
    filteredResults: [],
    fileQuery: null,
    fileTimeout: null,
    columns: [
      {
        headerName: "User",
        field: "assignedDocumentUserName",
        cellRenderer: "UserCell",
        cellRendererParams: (p) => {
          return p.data && p.data.assignedDocumentUser
            ? {
                name: p.data.assignedDocumentUser.firstname,
                surname: p.data.assignedDocumentUser.surname,
                avatar: p.data.assignedDocumentUser.avatar
              }
            : null;
        },
      },
      {
        headerName: "File No.",
        field: "fileNumber",
      },
      {
        headerName: "Type",
        field: "type",
      },
      {
        headerName: "Carrier",
        field: "shippingLineName",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.shippingLine
            ? {
                name: p.data.shippingLine.name,
                alias: p.data.shippingLine.alias,
                logo: p.data.shippingLine.logo,
                friendlyName: p.data.shippingLine.friendlyName,
              }
            : null;
        },
      },
      {
        headerName: "Vessel",
        field: "vesselVoyage",
      },
      {
        headerName: "POL",
        field: "portOfLoadValue",
        cellRenderer: "PortCell",
        cellRendererParams: (p) => {
          return {
            locode: p.data ? p.data.portOfLoadValue : null,
            name: p.data ? p.data.portOfLoadCity : null,
          };
        },
      },
      {
        headerName: "POD",
        field: "portOfDischargeValue",
        cellRenderer: "PortCell",
        cellRendererParams: (p) => {
          return {
            locode: p.data ? p.data.portOfDischargeValue : null,
            name: p.data ? p.data.portOfDischargeCity : null,
          };
        },
      },
      {
        headerName: "Destination",
        field: "finalDestinationValue",
        cellRenderer: "PortCell",
        cellRendererParams: (p) => {
          return {
            locode: p.data ? p.data.finalDestinationValue : null,
            name: p.data ? p.data.finalDestinationCity : null,
          };
        },
      },
      {
        headerName: "Shipper",
        field: "shipperName",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.shipper
            ? {
                name: p.data.shipper.name,
                alias: p.data.shipper.alias,
                logo: p.data.shipper.logo,
                friendlyName: p.data.shipper.friendlyName,
              }
            : null;
        },
      },
      {
        headerName: "Consignee",
        field: "consigneeName",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.consignee
            ? {
                name: p.data.consignee.name,
                alias: p.data.consignee.alias,
                logo: p.data.consignee.logo,
                friendlyName: p.data.consignee.friendlyName,
              }
            : null;
        },
      },
      {
        headerName: "Customer",
        field: "customerName",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.customer
            ? {
                name: p.data.customer.name,
                alias: p.data.customer.alias,
                logo: p.data.customer.logo,
                friendlyName: p.data.customer.friendlyName,
              }
            : null;
        },
      },
      {
        headerName: "ETD",
        field: "etd",
      },
      {
        headerName: "ETA",
        field: "eta",
      },
    ],
    gridApi: null,
    loading: false,
    previewShipmentFile: false,
    previewKey: 2000,
    search: null,
    searchTimeout: null,
    selectedFile: null,
    sidebar: {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          minWidth: 225,
          maxWidth: 225,
          width: 225,
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
          },
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
          minWidth: 180,
          maxWidth: 400,
          width: 250,
        },
      ],
    },
    statusBar: {
      statusPanels: [
        {
          statusPanel: "agTotalRowCountComponent",
          align: "left",
        },
        {
          statusPanel: "agFilteredRowCountComponent",
        },
        {
          statusPanel: "agSelectedRowCountComponent",
        },
        {
          statusPanel: "agAggregationComponent",
        },
      ],
    },
  }),
  watch: {
    opened: {
      immediate: true,
      handler(val) {
        if (val == true) {
          this.getFiles();
        } else {
          this.files = null;
        }
      },
    },
    search(val) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(async () => {
        this.gridApi.updateGridOptions({ quickFilterText: val });
      }, 400);
    },
  },
  computed: {},
  mounted() {
    if (!this.opened) {
      this.getFiles();
    }
  },
  beforeUnmount() {
    this.files = null;
  },
  methods: {
    cancelFile(id) {
      this.files = this.files.filter((x) => x.id != id);
      let rownNode = this.gridApi.getRowNode(id);
      if (rownNode) {
        rownNode.setData(null);
      }
      this.previewShipmentFile = false;
    },
    getContextMenuItems(params) {
      let result = [];
      if (!params.node.group) {
        result = [
          {
            name: "View File",
            icon: '<span class="ag-icon ag-icon-arrows" unselectable="on" role="presentation"></span>',
            action: () => {
              if (
                params.node &&
                params.node.data
              ) {
                this.viewShipmentFile(params.node);
              } else {
                this.$message.error(
                  "Please select a single row to view the file"
                );
              }
            },
          },
          "separator",
          {
            name: "Add to Filter",
            icon: '<span class="ag-icon ag-icon-filter" unselectable="on" role="presentation"></span>',
            action: () => {
              if (params.node && params.node.data) {
                let filter = this.gridApi.getFilterModel();
                if (filter[params.column.colId]) {
                  filter[params.column.colId].values.push(params.value);
                } else {
                  filter[params.column.colId] = {
                    values: [params.value],
                  };
                }
                this.gridApi.setFilterModel(filter);
              } else {
                this.$message.error(
                  "Please select a single row to view the file"
                );
              }
            },
          },
          "separator",
          "copy",
          "copyWithHeaders",
          "export",
          "chartRange",
        ];
      } else {
        result = ["copy", "copyWithHeaders", "export", "chartRange"];
      }

      return result;
    },
    onGridReady(params) {
      this.gridApi = params.api;
    },
    async getFiles() {
      this.loading = true;
      let obj = {};
      if (this.selectedUser && this.selectedUser.id) {
        obj.userId = this.selectedUser.id;
      }
      let filterFinal = null;
      // let {vesselVoyage, ...filterVessel} = this.filter
      if (
        this.filter &&
        this.filter.vesselVoyage &&
        this.filter.vesselVoyage?.voyage
      ) {
        filterFinal = {
          ...this.filter,
          vesselVoyage: {
            voyage: this.filter.vesselVoyage.voyage,
            id: this.filter.vesselVoyage.id,
          },
        };
      } else {
        filterFinal = { ...this.filter };
      }
      if (filterFinal) {
        obj = { ...obj, ...filterFinal };
      }
      if (this.week) {
        obj.week = this.week;
      }
      if (this.isDialog) {
        this.files = await this.$API.getReadyFiles({ params: obj });
      } else {
        this.files = await this.$API.getReadyFiles({});
      }
      this.loading = false;
    },

    updateData(data) {
      let findIndex = this.files.findIndex((x) => x.id == this.selectedFile.id);
      let findFilteredIndex = this.filteredResults.findIndex(
        (x) => x.id == this.selectedFile.id
      );

      if (findIndex > -1) {
        this.files[findIndex].status = "Ready";
      }

      if (findFilteredIndex > -1) {
        this.filteredResults[findIndex].status = "Ready";
      }
    },
    closeDialog() {
      this.files = [];
      this.$emit("close");
      this.$emit("refresh");
    },
    viewShipmentFile(file) {
      if (file.data) {
        this.selectedFile = file.data;
        this.previewKey++;
        this.previewShipmentFile = true;
      }
    },
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  height: 720px;
  margin: auto;
  /* outline: solid 1px black; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottomBar {
  height: 3rem;
  width: 100dvw;
  position: fixed;
  display: flex;
  padding: 0.2rem 0.5rem;
  bottom: 0;
  background-color: var(--v-greyRaised-base) !important;
  align-content: center;
}

.mobileToolbarCard :is(.v-toolbar__content) {
  padding: 5px !important;
}
</style>